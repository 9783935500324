import React from 'react'
import ContactForm from './contactForm'
import Section from './section'

const ContactFormSection = ({heading, text}) => {
  return (
    <>
      <Section last id="contact-form" >
        <div className="row">
          <div className="col">
            <div className="headings">
              <h2>{heading}</h2>
              <p className="subheading">{text}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ContactForm />
          </div>
        </div>
      </Section>
    </>
  )
}

export default ContactFormSection