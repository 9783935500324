import React from 'react'

import styled from 'styled-components'

const BackgroundImage = styled.div`
  display: none;
  margin: -160px auto -100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    display: block;
    position: absolute;
    margin: 0;
    height: 80%;
    top: 200px;
    right: -300px;
    left: 0;
    z-index: -1;
  }
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    height: 120%;
    max-height: 1200px;
    top: 0;
    right: -400px;
    background-size: contain;
    background-position-x: 5em;
    background-position-y: bottom;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    background-position-x: 10em;
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    background-position-x: center;
  }
`

const HeroBg = ({bgImage}) => {

  const image = bgImage

  return (
    <BackgroundImage 
      style={{backgroundImage: `url(${image})`}}
    >
    </BackgroundImage>
  )
}

export default HeroBg
