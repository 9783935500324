import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import QuoteBlock from './quoteBlock'

const Quotes = () => {

  const data = useStaticQuery(
    graphql`
      query MyTestimonials {
        allTestimonialsJson {
          edges {
            node {
              company
              id
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
              name
              text
              website
            }
          }
        }
      }
    `
  )

  return (
    <>
      {data.allTestimonialsJson.edges.map((testimonial) => {

        const id = testimonial.node.id
        const text = testimonial.node.text
        const link = testimonial.node.website
        const company = testimonial.node.company
        const name = testimonial.node.name
        const image = testimonial.node.image

        return (
          <div key={id} className="col-lg">
            <QuoteBlock 
                quote={text}
                testimonialLink={link}
                testimonialCompany={company}
                testimonialName={name}
                testimonialImage={image}
            />
          </div>
        )
      })}
    </>
  )
}

export default Quotes

