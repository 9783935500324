import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled from "styled-components"
import * as variables from "../utilities/style-variables"

const Wrapper = styled.div`
    position: relative;
    margin: 0 auto 100px;
    padding: 40px 20px;
    max-width: 450px;
    background-color: ${variables.white};
    transform: skew(0deg, 5deg);

    &:first-child{
        margin-top: 80px;

        @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
            margin-top: 50px;
        } 
    }
`

const ProfileImage = styled.div`
    display: block;
    width: 100px;
    height: 100px;
    margin: -100px auto 0;
    position: relative;
    transform: skew(0deg, -5deg);
    overflow: hidden;
    filter: sepia(1) grayscale(100%) contrast(120%) brightness(0.9);
`

const Quote = styled.blockquote`
    padding: 50px 0 20px;
    font-family: ${variables.brand_font};
    font-size: ${variables.font_size__mobile_small};
    font-weight: ${variables.font_regular};
    transform: skew(0deg, -5deg);

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
        padding: 50px 20px 20px;
        font-size: ${variables.font_size__desktop_small}
    } 

    &::before,::after {
        content: '"';
        display: inline-block;
    }
`

const Ref = styled.div`
    margin-bottom: 30px;
    font-style: italic;
    font-weight: ${variables.font_bold};
    text-align: center;
    transform: skew(0deg, -5deg);
`

const Name = styled.p`
  margin-bottom: 0px;
  font-style: italic;
  font-weight: ${variables.font_regular};
  text-align: center;
`

const QuoteBlock = ({quote, testimonialLink, testimonialCompany, testimonialImage, testimonialName}) => {

    const image = getImage(testimonialImage)
  
    return (
        <Wrapper>
            <ProfileImage>
                <GatsbyImage 
                    image={image}
                    alt={testimonialCompany}
                />
            </ProfileImage>
            <Quote>{quote}</Quote>
            <Ref>
                {testimonialName ? 
                  <Name>{testimonialName}</Name> : null
                }
                <a href={testimonialLink} target="_blank" rel="noreferrer">{testimonialCompany}</a>
            </Ref>
        </Wrapper>
    )
}

export default QuoteBlock
