import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Btn from "../components/button"
import List from "../components/list"
import Section from "../components/section"
import ContentBlock from "../components/contentBlock"
import ContactFormSection from "../components/contactFormSection"
import Quotes from "../components/quotes"
import bgImage from "../images/backgrounds/hero-website-home.png"
import HeroBg from "../components/heroBg"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = ({ location }) => (

  <>
  {/* Hero section  */}
  <Layout>
    <Seo 
      title="Online Marketing & Websites müssen nicht komplex sein | cliqit"
      description="cliqit Home - Online Marketing, SEO, Websites und Webtracking. Komplexe Themen wie Online Marketing und Website-optimierungen einfach und verständlich umgesetzt."
      lang="de"
      pathname={location.pathname}
      robots="index,follow"
    />
    <Section noSeparator>
      <div className="row">
        <div className="col-md-8">
          <h1 className="small">Die Agentur für digitales Marketing & Websites</h1>
          <h2 className="main-heading text-shadow">Online Werbung & Websites müssen nicht kompliziert sein</h2>
          <List 
            listItems={[
              { text:"Suchmaschinenmarketing", href:"/online-marketing#plattformen", key:"1" },
              { text:"Social Media Marketing", href:"/online-marketing#plattformen", key:"2" },
              { text:"Website Design & Optimierungen (SEO)", href:"/website-und-seo#von-der-idee-zur-website", key:"3" },
              { text:"Web-Tracking & Web Analytics", href:"/webtracking#warum-webtracking", key:"4" },
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 d-flex justify-content-around justify-content-md-start">
          <Btn
            type="primary"
            text="Kontakt aufnehmen"
            href="#contact-form"
          />
        </div>
      </div>
    </Section>
    <HeroBg 
      bgImage={bgImage}
    />

    <Section>
      <div className="row">
        <div className="col">
          <div className="headings">
            <h2>
              <span className="h2-1">Wie wirst du gefunden?</span>
              <span className="h2-2">Wie erhältst du eine große Reichweite?</span>
              <span className="h2-3">Was bringen dir deine Maßnahmen?</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6 order-md-last">
          <StaticImage 
            src="../images/graphics/grafik-online-marketing.png" 
            alt="Gutes Online Marketing führt zum Erfolg." 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Online Marketing | Gesehen. Gesucht. Gefunden."
            text={<>Von <a href="/online-marketing#google-ads">Google Ads</a> über <a href="/online-marketing#facebook-ads">Facebook Marketing</a> bis hin zu <a href="/online-marketing#linkedin-ads">LinkedIn Ads & mehr</a>.
            Online Marketing Plattformen bieten eine Vielzahl an Werbeformaten und Platzierungsmöglichkeiten.
            Egal ob Videoanzeigen, Werbebanner oder Audio-Spots: Für jedes Marketingziel gibt es die passende Lösung,
            mit der du gefunden und gecliqt wirst.</>}
            btnText="Mehr über Online Marketing"
            linkTo="/online-marketing"
            headingBgStart={-5}
            headingBgWidth={80}
          />
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6">
          <StaticImage 
            src="../images/graphics/grafik-website-und-design.png" 
            alt="Website Optimierungen und Design - So geht SEO" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Website | SEO & Design führen zum Erfolg."
            text={<>Deine Website ist entscheidend für deinen Unternehmenserfolg.<br />
            Erziele den Durchbruch in drei einfachen Schritten.<br /><br />
            Schritt 1: Optimiere deine Website für <a href="/website-und-seo#seo-teil-1">Suchmaschinen (SEO)</a><br />
            Schritt 2: Leite deine Webseitenbesucher durch gutes <a href="/website-und-seo#webdesign">UX-Design</a> zu
            gewünschten Aktionen. Von der Newsletter Anmeldung bis zum Kauf.<br />
            Schritt 3: Teste in <a href="/website-und-seo#a-b-testing">A/B-Tests</a> welche Varianten mehr Erfolgspotenzial
            aufweisen.</>}
            btnText="Mehr über Websites & SEO"
            linkTo="/website-und-seo"
            headingBgStart={5}
            headingBgWidth={50}
          />
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6 order-md-last">
          <StaticImage 
            src="../images/graphics/grafik-webtracking.png" 
            alt="Mit Webtracking Erfolge messbar machen." 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Web-Tracking | Wo wurde gecliqt?"
            text={<>Über welche Werbekampagne sind Nutzer auf deine Website gelangt?
            Wie verhalten sich Nutzer auf deiner Website? ...<br /><br />
            Fragen über Fragen, die wir zusammen mit Hilfe von Web-Tracking
            und deinen neuen Lieblingstools wie z.B. <a href="/webtracking#google-analytics-setup">Google Analytics</a> beantworten.</>}
            btnText="Mehr über Web-Tracking"
            linkTo="/webtracking"
            headingBgStart={-5}
            headingBgWidth={60}
          />
        </div>
      </div>
    </Section>

    <Section bgGradient spikes>
      <div className="row">
        <div className="col">
          <div className="headings">
            <h2>Erfolgsgeschichten</h2>
            <p className="subheading">… erzählen mehr als tausend Worte.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <Quotes />
      </div>
    </Section>

    <ContactFormSection 
      heading="Erzähle uns von deinen Ideen"
      text="Schicke uns eine Nachricht mit deinem Anliegen oder deinen Fragen."
    />
    
  </Layout>
  </>
)
export default IndexPage
